import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

function Footer() {
    return (
        <div className="w-full bg-sphinx-blue">
            <div className="container mx-auto px-8">
                <div className="py-8 font-montserrat font-light text-sphinx-white text-center text-base tracking-widest border-b md:space-x-4">
                    <p className="py-1 md:inline-block">3030 LBJ Freeway Suite 1350 Dallas, Texas 75234 <span className="px-2">/</span></p>
                    <p className="py-1 md:inline-block">(214) 342-1400 <span className="px-2">/</span></p>
                    <p className="py-1 md:inline-block">Info@sdcus.com</p>
                </div>
            
                <div className="pt-8 font-montserrat font-light text-sphinx-white text-center text-xs tracking-widest">
                    <div className="block mb-4">
                        <p className="py-1 md:inline-block">Copyright 2021 Sphinx Residential.</p>
                        <p className="py-1 md:inline-block">All Rights Reserved.<span className="px-2">/</span><a href="/portfolio" className="max-w-max hover:underline">Site Map</a></p>
                    </div>
                    <div className="space-x-6 md:text-right">
                    <StaticImage src="../images/Icons/housing-disability-logos.png" width={85}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
