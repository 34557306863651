import React, {useState} from "react"

import { StaticImage } from 'gatsby-plugin-image'

import { Link } from "gatsby"

import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useMediaQuery } from 'react-responsive'

export default function Nav(props) {
    const [open, setOpen] = useState(false);
    const [aboutDropdown, setaboutDropdown] = useState(false);
    const [colorNav, setColorNav] = useState(false);

    let active = 0
    let isActive = props.isActive;
    switch(isActive) {
      case 'Home':
          active = 0;
          break;
      case 'About':
          active = 1;
          break;
      case 'Portfolio':
          active = 2;
          break;
      case 'Contact':
          active = 3;
        break;
      default:
          active = 0;
          break;
  }

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y < -180
    setColorNav(isShow)
  },[colorNav],null,false,0)

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  //cases for div with id = "innerNavBar"
  let classesForInnerNavBar = "";
  if( active !== 0 && !isMobile) {
    classesForInnerNavBar = "bg-opacity-100"
  } else if (active === 0 && !isMobile && !colorNav) {
    classesForInnerNavBar = "bg-opacity-0"
  } else if (active === 0 && !isMobile && colorNav) {
    classesForInnerNavBar = "bg-opacity-100"
  } else if(active === 0 && isMobile && !colorNav) {
    classesForInnerNavBar = "bg-opacity-0"
  }

  // cases for div containining only Logo Image and Hamburger icon
  let classesForLogoAndHamburgerDiv = "";
  if (active === 0 && !isMobile) {
    classesForLogoAndHamburgerDiv = "bg-opacity-0"
  } else if ( active === 0 && isMobile && !colorNav) {
    classesForLogoAndHamburgerDiv = "bg-opacity-0"
  }  else if ( active === 0 && isMobile && colorNav) {
    classesForLogoAndHamburgerDiv = "bg-opacity-100"
  }

    return (
        <div className="w-full fixed z-50" onMouseLeave={() => setaboutDropdown(false)}>
          
            <div id="innerNavBar" className={"flex flex-col max-h-screen md:flex-row md:h-auto md:items-center transition duration-300 ease-in-out bg-sphinx-blue " + classesForInnerNavBar + (open ? " h-screen bg-opacity-100" : " bg-opacity-0 h-16")}>
              <div id="divWithLogoAndHamburger" className={ classesForLogoAndHamburgerDiv + " transition duration-300 ease-in-out px-7 bg-sphinx-blue md:flex-1 flex items-center"}>
                <div className="flex-1 py-2">
                  <Link to="/">
                    <StaticImage src="../images/Icons/logo.png" placeholder="tracedSVG" alt="logo" width={180} loading="eager" />
                  </Link>
                </div>
                <div className="md:hidden" onClick={() => setOpen(!open)}>
                  <StaticImage src="../images/Icons/hamburger-icon-white.svg" placeholder="tracedSVG" alt="Navigation" height={25} loading="eager"/>
                </div>
              </div>
            
              <nav className={ (open || !isMobile ? "translate-x-0 duration-700 transition ease-in-out" : "-translate-x-full") + " transform flex-1 overflow-y-auto md:overflow-y-visible md:flex-none px-7"}>
                <ul className="md:flex pb-4 text-sphinx-white bg-transparent divide-y divide-sphinx-light-blue space-y-4 md:divide-y-0 md:space-y-0 md:pb-0">
                  <li className="py-1 md:px-3">
                  <Link to="/" className={(active == 0 ? "text-gray-400" : "hover:text-white") + " font-montserrat text-base text-gray-300 transition duration-300 ease-in-out"}>Home</Link></li>
                  <li className="py-1 md:px-3">
                    <Link to="/about" onMouseEnter={() => setaboutDropdown(!aboutDropdown)} className={(active == 1 ? "text-gray-400" : "hover:text-white") + " font-montserrat text-base text-gray-300 transition duration-300 ease-in-out"}>About</Link>
                      <ul class={( aboutDropdown ? "block" : "md:hidden") + " md:absolute text-gray-300 pt-1 font-montserrat text-base z-60"}>
                        <li>
                          <Link to="/development" className="rounded-t md:bg-sphinx-blue hover:text-white py-2 px-4 block whitespace-no-wrap transition duration-300 ease-in-out">Sphinx Development</Link>
                        </li>
                        <li>
                          <Link to="/construction" className="md:bg-sphinx-blue hover:text-white py-2 px-4 block whitespace-no-wrap transition duration-300 ease-in-out"> SDC Construction</Link>
                        </li>
                        {/* <li>
                          <Link to="/ventures" className="md:bg-sphinx-blue hover:text-white py-2 px-4 block whitespace-no-wrap transition duration-300 ease-in-out">
                            Joint Ventures
                          </Link>
                        </li> */}
                        <li>
                          <Link to="/leadership" className="md:bg-sphinx-blue hover:text-white py-2 px-4 block whitespace-no-wrap transition duration-300 ease-in-out">
                            Sphinx Leadership
                          </Link>
                        </li>
                      </ul>
                  </li>
                  <li className="py-1 md:px-3">
                    <Link to="/portfolio" className={(active == 2 ? "text-gray-400" : "hover:text-white") + " font-montserrat text-base text-gray-300 transition duration-300 ease-in-out"}>Portfolio</Link>
                  </li>
                  <li className="py-1 md:px-3">
                    <Link to="/contact" className={(active == 3 ? "text-gray-400" : "hover:text-white") + " font-montserrat text-base text-gray-300 transition duration-300 ease-in-out"}>Contact</Link>
                  </li>
                  <li className="py-1 md:px-3"><a href="https://sphinxres.myresman.com/Portal/Access/SignIn" className={(active == 4 ? "text-gray-400" : "hover:text-white") + " font-montserrat text-base text-gray-300 transition duration-300 ease-in-out"}>Residents</a>
                  </li>
                </ul>
              </nav>
          </div>
        </div>
    )
}
